import React from 'react';
import { PT } from '_constants/proptypes';

export const FacebookSvg = ({ color, backgroundColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <path fill={backgroundColor} d="M0 0H15V15H0z" />
    <path id="FacebookSvg" fill={color} d="M418.55 168.15V162h2.073l.311-2.407h-2.384v-1.533c0-.7.193-1.169 1.19-1.169H421v-2.147a17.007 17.007 0 0 0-1.852-.095 2.891 2.891 0 0 0-3.086 3.172v1.771H414V162h2.065v6.149z" transform="translate(-410 -153.65)" />
  </svg>

);

FacebookSvg.propTypes = {
  color: PT.string,
  backgroundColor: PT.string,
};

FacebookSvg.defaultProps = {
  color: '#fff',
  backgroundColor: '#4267b2',
};
