import React from 'react';
import styled from 'styled-components';
import useUser from 'lib/useUser';
import { mediaQueries } from '_constants/cssVars';
import ProfileSvg from '../SVGs/Profile';
import { useLogoutModal } from '../PredictorContainer/LogoutModalProvider';

const Text = styled.span`
  font-size: 12px;
  font-weight: 500;
`;
const Button = styled.button`
  flex: flex-grow;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;

  @media ${mediaQueries.mobile} {
    ${Text} {
      display: none;
    }
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 25px;
`;

type ProfileImageProps = {
  url?: string;
};

const ProfileImage: React.FC<ProfileImageProps> = ({ url }) => {
  if (!url) return <ProfileSvg />;

  return <Image src={url} alt="Profile picture" />;
};

const LogoutButton: React.FC = () => {
  const openModal = useLogoutModal();
  const { readOnlyUser, imageUrl } = useUser();

  if (readOnlyUser) {
    return null;
  }

  return (
    <Button onClick={() => {
      openModal(true);
    }}
    >
      <ProfileImage url={imageUrl} />
    </Button>
  );
};

export default LogoutButton;
