import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { getOrigin } from 'helpers/getOrigin';
import { useTranslation } from 'react-i18next';
import { useTournamentId } from 'lib/useTournamentId';
import { getTournamentImageUrl } from '../Pages/helpers/index';

const defaultPromoImg = `${getOrigin()}/img/euro2024-promo.png`;

const PredictorHead = ({
  gameConfig,
  title,
  description,
  imageUrl,
  imageWidth,
  imageHeight,
}) => {
  const { asPath, query } = useRouter();
  const { t } = useTranslation();
  const id = useTournamentId();

  let promoImg = gameConfig?.promoImg ?? defaultPromoImg;
  if (promoImg.startsWith('/')) {
    promoImg = getOrigin() + promoImg;
  }

  const defaultImgHeight = promoImg ? '1280' : '192';
  const defaultImgWidth = promoImg ? '2260' : '192';
  const tournamentImageUrl = imageUrl || promoImg || getTournamentImageUrl(id);
  const predictor = t('startpage_predictor_competition').toLowerCase();
  const defaultTitle = gameConfig && gameConfig.title ? `FotMob ${gameConfig.title} ${predictor}` : `FotMob ${predictor}`;
  const defaultDescription = query?.code ? 'Join my league' : t('startpage_predict_the_outcome_v2');

  const getImageUrls = () => {
    if (tournamentImageUrl) {
      return (
        <>
          <meta property="og:image" content={tournamentImageUrl} />
          <meta property="og:image:secure_url" content={tournamentImageUrl} />
          <meta property="og:image:url" content={tournamentImageUrl} />
          <meta property="twitter:image" content={tournamentImageUrl} />
        </>
      );
    }
    return null;
  };

  const imageUrls = getImageUrls();

  const getImageSizes = () => {
    if (!(tournamentImageUrl)) return null;
    return (
      <>
        <meta property="og:image:width" content={imageWidth || defaultImgWidth} />
        <meta property="og:image:height" content={imageHeight || defaultImgHeight} />
      </>
    );
  };

  const imageSizes = getImageSizes();

  const pageTitle = gameConfig?.mustNotBeNamed
    ? 'FotMob Tournament Predictor'
    : title || defaultTitle;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      {imageUrls}
      {imageSizes}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://predict.fotmob.com${asPath}`} />
      <meta
        key="twitter:card"
        property="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:title" property="twitter:title" content={pageTitle} />
    </Head>
  );
};

PredictorHead.propTypes = {
  gameConfig: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
};

PredictorHead.defaultProps = {
  gameConfig: undefined,
  title: '',
  description: '',
  imageUrl: '',
  imageWidth: '',
  imageHeight: '',
};

export default PredictorHead;
