import React from 'react';
import styled from 'styled-components';
import { PT } from '_constants/proptypes';
import { applyLightHover } from '../../../../styles/common.styles';

const FotMobLogoCSS = styled.div`
  ${applyLightHover};
`;

const FotMobLogo = ({ className, color }) => (
  <FotMobLogoCSS
    className={className}
    aria-label="fotmob.com livescores"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 96 16"
    >
      <g id="ic_fotmob_logo" transform="translate(-586 -21)">
        <path fill={color} id="Path_1" d="M91.874 7.715c1.61-.57 2.784-1.677 2.784-3.388 0-3.187-2.583-4.159-5.736-4.159h-4.8a.888.888 0 0 0-.872.872V15.8h6.172c3.79 0 5.669-2.08 5.669-4.6.003-1.842-1.07-2.982-3.217-3.485zM86.1 2.65h2.784c1.979 0 2.583.771 2.583 2.046 0 2.583-2.952 1.945-5.333 2.046V2.65zm3.22 10.633h-3.182V9.191h3.187c1.476 0 2.583.5 2.583 1.979 0 1.509-1.074 2.113-2.583 2.113zM44.31 1.04v2.046h-4.7V15.8H36.7V3.086H32V1.04a.888.888 0 0 1 .872-.872h10.6a.859.859 0 0 1 .838.872zm10.566 12.847a1.152 1.152 0 0 1-1.04-.637l-4.8-8.889a8.768 8.768 0 0 1 .071 1.339v10.1h-2.885V1.04a.888.888 0 0 1 .872-.872c2.516 0 2.516-.034 2.751.067a.734.734 0 0 1 .335.3c4.159 7.849 4.361 7.983 4.662 8.922.3-.939.5-1.073 4.662-8.922a.589.589 0 0 1 .335-.3 12.368 12.368 0 0 1 2.752-.067.888.888 0 0 1 .872.872V15.8h-2.884V5.7c0-.436.034-.906.067-1.342l-4.8 8.889a.95.95 0 0 1-.973.637zM23.312 0A7.644 7.644 0 0 0 15.4 7.983 7.736 7.736 0 0 0 23.312 16a7.736 7.736 0 0 0 7.916-8.017A7.606 7.606 0 0 0 23.312 0zm0 13.317c-2.348 0-4.8-1.509-4.8-5.333 0-3.119 1.677-5.333 4.8-5.333s4.8 2.214 4.8 5.333c-.003 3.823-2.418 5.366-4.8 5.333zM73.359 0a7.644 7.644 0 0 0-7.916 7.983A7.736 7.736 0 0 0 73.359 16a7.736 7.736 0 0 0 7.916-8.017A7.625 7.625 0 0 0 73.359 0zm0 13.317c-2.348 0-4.8-1.509-4.8-5.333 0-3.119 1.677-5.333 4.8-5.333s4.8 2.214 4.8 5.333c-.004 3.823-2.452 5.366-4.8 5.333zM5.635 11.74v4.025H2.717v-2.448zm9.157-10.7v2.046H5.635v4.059l-2.918.7V1.04a.888.888 0 0 1 .872-.872H13.92a.888.888 0 0 1 .872.872zm-8.15 7.547L0 11.2V9.291l6.742-1.643a3.206 3.206 0 0 0-.1.939zm.168.771a3.47 3.47 0 0 0 .369.7L0 13.92v-1.912zM9.392 6.44a1.983 1.983 0 0 1 1.979 1.979 1.979 1.979 0 1 1-3.958 0A1.983 1.983 0 0 1 9.392 6.44" className="cls-1" data-name="Path 1" transform="translate(586.889 21)" />
        <path fill="none" id="Rectangle_3401" d="M0 0h96v16H0z" className="cls-2" data-name="Rectangle 3401" transform="translate(586 21)" />
      </g>
    </svg>
  </FotMobLogoCSS>
);

FotMobLogo.propTypes = {
  color: PT.string,
  className: PT.string,
};

FotMobLogo.defaultProps = {
  color: '#333',
  className: '',
};
export default FotMobLogo;
