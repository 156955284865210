import React from 'react';
import { PT } from '_constants/proptypes';

export const InstagramSvg = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <path fill="none" d="M0 0H15V15H0z" />
    <path id="InstagramSvg" fill={color} d="M11.25 0h-7.5A3.761 3.761 0 0 0 0 3.75v7.5A3.761 3.761 0 0 0 3.75 15h7.5A3.761 3.761 0 0 0 15 11.25v-7.5A3.761 3.761 0 0 0 11.25 0zM7.5 10.625A3.125 3.125 0 1 1 10.625 7.5 3.126 3.126 0 0 1 7.5 10.625zm4.063-6.25a.938.938 0 1 1 .938-.938.936.936 0 0 1-.938.938z" />
  </svg>
);

InstagramSvg.propTypes = {
  color: PT.string.isRequired,
};

InstagramSvg.defaultProps = {};
