import React from 'react';
import { PT } from '_constants/proptypes';

export const LinkedInSvg = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      id="LinkedInSvg"
      d="M11.875 0h-8.75A3.125 3.125 0 0 0 0 3.125v8.75A3.125 3.125 0 0 0 3.125 15h8.75A3.125 3.125 0 0 0 15 11.875v-8.75A3.125 3.125 0 0 0 11.875 0zM5 11.875H3.125V5H5zm-.937-7.667a1.1 1.1 0 1 1 1.094-1.1 1.1 1.1 0 0 1-1.094 1.1zm8.437 7.667h-1.875v-3.5c0-2.1-2.5-1.946-2.5 0v3.5H6.25V5h1.875v1.1A2.377 2.377 0 0 1 12.5 7.651z"
      fill={color}
    />
  </svg>
);

LinkedInSvg.propTypes = {
  color: PT.string,
};

LinkedInSvg.defaultProps = {
  color: '#333',
};
