import React, { ReactElement } from 'react';

interface Props {
  backgroundColor?: string;
}

const DownloadGooglePlaySvg = ({
  backgroundColor = '#fff',
}: Props): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1=".915"
        x2="-.384"
        y1="6.617"
        y2="5.947"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#00a0ff" />
        <stop offset=".007" stopColor="#00a1ff" />
        <stop offset=".26" stopColor="#00beff" />
        <stop offset=".512" stopColor="#00d2ff" />
        <stop offset=".76" stopColor="#00dfff" />
        <stop offset="1" stopColor="#00e3ff" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="1.076"
        x2="-1.305"
        y1="17.089"
        y2="17.089"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ffe000" />
        <stop offset=".409" stopColor="#ffbd00" />
        <stop offset=".775" stopColor="orange" />
        <stop offset="1" stopColor="#ff9c00" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1=".863"
        x2="-.502"
        y1="10.864"
        y2="9.095"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ff3a44" />
        <stop offset="1" stopColor="#c31162" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="-.188"
        x2=".421"
        y1="13.585"
        y2="12.794"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#32a071" />
        <stop offset=".068" stopColor="#2da771" />
        <stop offset=".476" stopColor="#15cf74" />
        <stop offset=".801" stopColor="#06e775" />
        <stop offset="1" stopColor="#00f076" />
      </linearGradient>
    </defs>
    <g id="ic_play_store" transform="translate(-753 -5)">
      <circle
        id="Ellipse_311"
        cx="25"
        cy="25"
        r="25"
        fill={backgroundColor}
        transform="translate(753 5)"
      />
      <g id="Group_5439" transform="translate(768.944 17.685)">
        <path
          id="Path_2211"
          fill="url(#linear-gradient)"
          d="M10.421 7.537a1.927 1.927 0 0 0-.441 1.341v21.183a1.894 1.894 0 0 0 .441 1.341l.067.067 11.865-11.856v-.287L10.488 7.47z"
          transform="translate(-9.97 -7.155)"
        />
        <path
          id="Path_2212"
          fill="url(#linear-gradient-2)"
          d="M26.855 23.918L22.9 19.962v-.287l3.955-3.955.086.048 4.683 2.662c1.341.757 1.341 2 0 2.768l-4.683 2.662z"
          transform="translate(-10.517 -7.504)"
        />
        <path
          id="Path_2213"
          fill="url(#linear-gradient-3)"
          d="M26.414 24.051L22.373 20 10.44 31.933a1.553 1.553 0 0 0 1.992.057l13.982-7.939"
          transform="translate(-9.99 -7.685)"
        />
        <path
          id="Path_2214"
          fill="url(#linear-gradient-4)"
          d="M26.413 15.414L12.422 7.466a1.553 1.553 0 0 0-1.992.057l11.942 11.933z"
          transform="translate(-9.989 -7.141)"
        />
      </g>
      <g id="Group_4662" transform="translate(0.01 16.27)">
        <path
          id="Path_2215"
          d="M26.328 24.13l-13.905 7.9a1.591 1.591 0 0 1-1.915.01l-.067.067.067.067a1.592 1.592 0 0 0 1.915-.01l13.992-7.949z"
          opacity="0.2"
          transform="translate(-9.999 -24.13)"
        />
        <path
          id="Path_2216"
          opacity=".12"
          d="M10.421 32.261a1.927 1.927 0 0 1-.441-1.341v.144a1.894 1.894 0 0 0 .441 1.341l.067-.067z"
          className="cls-7"
          transform="translate(-9.98 -24.417)"
        />
        <path
          id="Path_2217"
          opacity=".12"
          d="M31.8 21.245l-4.779 2.71.086.086 4.683-2.662A1.683 1.683 0 0 0 32.795 20a1.742 1.742 0 0 1-.995 1.245z"
          className="cls-7"
          transform="translate(-10.692 -7.685)"
        />
        <path
          id="Path_2218"
          fill="#fff"
          d="M12.4 7.6l18.677 10.611a1.79 1.79 0 0 1 1.006 1.245 1.671 1.671 0 0 0-1.006-1.379L12.4 7.466c-1.338-.757-2.43-.125-2.43 1.408v.144c0-1.543 1.101-2.175 2.43-1.418z"
          opacity="0.25"
          transform="translate(-9.97 -7.141)"
        />
      </g>
    </g>
  </svg>
);

export default DownloadGooglePlaySvg;
