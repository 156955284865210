import { css } from 'styled-components';

export const applyHover = css`
  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.4;
    }
  }
`;

export const applyLightHover = css`
  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.75;
    }
  }
`;
