import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadAppleStoreSvg from 'components/Predictor/Components/SVGs/DownloadAppleStoreSvg';
import DownloadGooglePlaySvg from 'components/Predictor/Components/SVGs/DownloadGooglePlaySvg';
import {
  facebookLink,
  getAppleStoreLink,
  getGooglePlayLink,
  instagramLink,
  linkedInLink,
  twitterLink,
} from '_constants/links';
import FotMobLogo from 'components/Predictor/Components/SVGs/FotmobLogo';
import { InstagramSvg } from 'components/Predictor/Components/SVGs/InstagramSvg';
import { FacebookSvg } from 'components/Predictor/Components/SVGs/FacebookSvg';
import { LinkedInSvg } from 'components/Predictor/Components/SVGs/LinkedInSvg';
import { XSvg } from 'components/Predictor/Components/SVGs/XSvg';
import {
  ApplinksContainer,
  AppLinkList,
  ApplinksText,
  CopyrightContainer,
  CopyrightText,
  FollowUsContainer,
  FollowUsText,
  FooterCSS,
  FullExperienceContainer,
  PSPLogoContainer,
  SoMeLinksContainer,
  TitleAndCopyright,
  TitleContainer,
} from './predictorStartPage.styles';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const followUs = t('follow_us');
  const footMobIsEssensial = t('fotmob_is_the_essential_app');
  const getTheFullExperience = t('get_the_app');

  const fullYear = new Date().getFullYear();
  const copyrightText = `© Copyright ${fullYear} FotMob`;

  return (
    <FooterCSS>
      <FullExperienceContainer>
        <PSPLogoContainer className="onlyMobile">
          <FotMobLogo size={40} />
        </PSPLogoContainer>
        <TitleAndCopyright>
          <TitleContainer>
            {footMobIsEssensial}
          </TitleContainer>
          <CopyrightText className="onlyDesktop">
            {copyrightText}
          </CopyrightText>
        </TitleAndCopyright>
        <ApplinksContainer>
          <ApplinksText>
            {getTheFullExperience}
          </ApplinksText>
          <AppLinkList>
            <a href={getAppleStoreLink()} rel="noopener noreferrer" target="_blank" aria-label="AppleStore">
              <DownloadAppleStoreSvg />
            </a>
            <a href={getGooglePlayLink()} rel="noopener noreferrer" target="_blank" aria-label="GooglePlay">
              <DownloadGooglePlaySvg />
            </a>
          </AppLinkList>
        </ApplinksContainer>
      </FullExperienceContainer>
      <FollowUsContainer>
        <PSPLogoContainer className="onlyDesktop">
          <FotMobLogo size={40} />
        </PSPLogoContainer>
        <FollowUsText className="onlyMobile">
          {followUs}
        </FollowUsText>
        <SoMeLinksContainer>
          <a href={instagramLink} rel="noopener noreferrer" target="_blank" aria-label="Instagram">
            <InstagramSvg color="#333" />
          </a>
          <a href={facebookLink} rel="noopener noreferrer" target="_blank" aria-label="Facebook">
            <FacebookSvg color="#333" backgroundColor="none" />
          </a>
          <a href={twitterLink} rel="noopener noreferrer" target="_blank" aria-label="X">
            <XSvg color="#333" />
          </a>
          <a href={linkedInLink} rel="noopener noreferrer" target="_blank" aria-label="Linkedin">
            <LinkedInSvg color="#333" />
          </a>
        </SoMeLinksContainer>
      </FollowUsContainer>
      <CopyrightContainer>
        <CopyrightText>
          {copyrightText}

        </CopyrightText>
      </CopyrightContainer>
      Version:
      {' '}
      {process.env.NEXT_PUBLIC_VERSION}
    </FooterCSS>
  );
};
export default Footer;
