import styled, { css } from 'styled-components';
import { mediaQueries, widths } from '_constants/cssVars';
import LogoutButton from 'components/Predictor/Components/Login/LogoutButton';
import { applyLightHover } from '../../../../../styles/common.styles';

export const SPBGContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const PSPWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.aboutText};
  background:${(p) => p.theme.background2};
`;

/*
 * CSS for MainSection.tsx
 */

export const StartPageLogoutButton = styled(LogoutButton)``;

export const PSPMainSection = styled.section`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  color: ${(p) => p.theme.startPageText};

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(p) => p.theme.main1};

  background-image: url("${(p) => p.theme.backgroundUrlMobile?.[0] || p.theme.main1}");

  @media ${mediaQueries.tablet} {
    padding: 124px 0 0 0;
  }

  z-index: 1;
  padding: 114px 0 81px 0;

  @media ${mediaQueries.desktop} {
    background-position: right;
    background-image: url("${(p) => p.theme.backgroundUrl?.[0] || p.theme.main1}");
  }
`;

export const PSPMainPageWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: ${widths.maxMobileContentWidth};

  @media ${mediaQueries.tablet} {
    max-width: ${widths.maxTabletContentWidth};
  }

  @media ${mediaQueries.desktop} {
    max-width: ${widths.maxContentWidth};
  }
`;

export const PSPMainPageContent = styled.div<{hasImage: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${(p) => (p.hasImage
    ? `@media ${mediaQueries.tablet} {
      justify-content: flex-start;
      width: 370px;
    }`
    : '')};
`;

export const PSPMainPageImage = styled.div`
  display: none;
    img {
      display:none;
    }

  @media ${mediaQueries.tablet} {
    display: block;
    height: 596px;
    img {
      display: block;
      width: auto;
      max-height: 100%;
    }
  }
`;

export const PSPTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  button {
    fill: ${(p) => p.theme.startPageText};
    color: ${(p) => p.theme.startPageText};
    ${applyLightHover}
  }

  @media ${mediaQueries.tablet} {
    align-items: flex-start;
    gap: 10px;
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;

export const PSPLogoContainer = styled.div`
  width: 165px;
  height: 28px;
  object-fit: contain;
`;

export const PSPHeader = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  @media ${mediaQueries.tablet} {
    align-items: flex-start;
  }
`;

export const PSPHeaderTitleBold = styled.h3`
  margin-top: 109px;
  font-family: Rubik;
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;

  @media ${mediaQueries.tablet} {
    font-size: 44px;
    line-height: 1.14;

    text-align: left;
    margin-top: 80px;
  }

  @media ${mediaQueries.desktop} {
    margin-top: 104px;
  }
`;

export const PSPHeaderSubTitle = styled.span`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.startPageText};
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 10px;

  @media ${mediaQueries.tablet} {
    text-align: left;
  }
`;

export const PSPActionSectionContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  justify-content: center;
  align-items: center;

  gap: 30px;

  @media ${mediaQueries.tablet} {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
  }
`;

export const ButtonStyles = css`
  color: ${(p) => p.theme.btnText1};
  width: 230px;
  height: 55px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  letter-spacing: 0.02px;
  text-align: center;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: inline-block;
  :first-letter {
    text-transform: uppercase;
  }
`;

export const PSPActionButton = styled.button`
  ${ButtonStyles}
  background: ${(p) => p.theme.btnBg1};
  :hover {
    color: ${(p) => p.theme.activeText};
    background-color: ${(p) => p.theme.hover.btnBg1};
  }
`;

export const PSPInfoButton = styled.button`
  ${ButtonStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: ${(p) => p.theme.btnBg4};

  fill: ${(p) => p.theme.btnText1};
  :hover {
    fill: ${(p) => p.theme.activeText};
    color: ${(p) => p.theme.activeText};
    background-color: ${(p) => p.theme.hover.btnBg4};
  }
`;

export const PSPLogInContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mediaQueries.tablet} {
    justify-content: flex-start;
  }
`;

const logInTextStyles = css`
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: 0.28px;
  text-align: center;
  color: ${(p) => p.theme.startPageText};
`;

export const PSPLogInText = styled.span`
  ${logInTextStyles}
  margin-right: 4px;
`;

export const PSPLogInButton = styled.button`
  ${logInTextStyles}
  font-weight: bold;
  ${applyLightHover}
`;

/**
 * CSS for AboutTheCompetition.tsx
 */

export const PredictorStartPageAboutSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  width: 100%;
  max-width: ${widths.maxMobileContentWidth};
  padding: 50px 0;

  border-bottom: 0.5px solid #d8d8d8;

  @media ${mediaQueries.tablet} {
    max-width: ${widths.maxTabletContentWidth};
    gap: 20px;
  }

  @media ${mediaQueries.desktop} {
    max-width: ${widths.maxContentWidth};
    padding: 63px 0;
    flex-direction: row;
  }
`;

export const PSPAboutText = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: 2.4;
  text-align: left;
  align-items: center;
`;

export const PSPAboutButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media ${mediaQueries.tablet} {
    flex-direction: row;
  }
  @media ${mediaQueries.desktop} {
    gap: 15px;
  }
`;

/**
 * CSS for FollowInTheApp.tsx
 */

export const FollowInTheAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  max-width: ${widths.maxMobileContentWidth};
  padding: 40px 0 0 0;

  @media ${mediaQueries.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    max-width: ${widths.maxTabletContentWidth};
    padding: 75px 0 0 0;
  }

  @media ${mediaQueries.desktop} {
    max-width: ${widths.maxContentWidth};
  }
`;

export const FollowInTheAppContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  @media ${mediaQueries.mobile} {
    gap: 50px;
    text-align: center;
    align-items: center;
    overflow-wrap: normal;
  }
`;

export const FollowInTheAppTitle = styled.h2`
  font-size: 35px;
  font-weight: bold;
  line-height: 1.09;
`;

export const FollowInTheAppDescription = styled.span`
  font-size: 20px;
  line-height: 1.6;
  max-width: 500px;
`;

export const IconContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 84px);
  justify-items: center;
  align-items: center;
  gap: 20px 0;
  font-size: 15px;
`;

export const ImageContainer = styled.div`
  width: 290px;
  height: 433px;

    img {
      width: auto;
      max-height: 100%;
    }
`;

/**
 * CSS for Footer.tsx
 */

export const FooterCSS = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const FullExperienceContainer = styled.div`
  width: 100%;
  max-width: ${widths.maxMobileContentWidth};
  padding: 55px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  border-bottom: 0.5px solid #dedede;

  @media ${mediaQueries.mobile} {
    .onlyDesktop {
      display: none;
    }
  }

  @media ${mediaQueries.tablet} {
    max-width: ${widths.maxTabletContentWidth};
    flex-direction: row;
    .onlyMobile {
      display: none;
    }
  }

  @media ${mediaQueries.desktop} {
    max-width: ${widths.maxContentWidth};
    padding: 63px 0;
  }
`;

export const FollowUsContainer = styled.div`
  width: 100%;
  max-width: ${widths.maxMobileContentWidth};
  min-height: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${mediaQueries.mobile} {
    border-bottom: 0.5px solid #dedede;
    .onlyDesktop {
      display: none;
    }
  }

  @media ${mediaQueries.tablet} {
    max-width: ${widths.maxTabletContentWidth};
    .onlyMobile {
      display: none;
    }
  }

  @media ${mediaQueries.desktop} {
    max-width: ${widths.maxContentWidth};
    padding: 63px 0;
  }
`;

export const CopyrightContainer = styled.div`
  display: flex;
  padding: 32px 0;
  width: ${widths.maxMobileContentWidth};
  justify-content: flex-start;
  align-items: left;

  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

export const FollowUsText = styled.span`
  font-size: 15px;
  font-weight: 500;
`;

export const TitleAndCopyright = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleContainer = styled.div`
  max-width: 255px;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.38;
  letter-spacing: 0.01px;
  text-align: left;
`;

export const CopyrightText = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: 0px;
  text-align: left;
  color: #717171;
`;

export const ApplinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 27px;
  @media ${mediaQueries.tablet} {
    align-items: center;
  }
`;

export const ApplinksText = styled.div`
  padding-top: 8px;
  font-size: 18px;
  line-height: 0.94;
  letter-spacing: 0.01px;
`;

export const AppLinkList = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  svg {
    ${applyLightHover}
  }
`;

export const SoMeLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  svg {
    height: 20px;
    width: 20px;
    ${applyLightHover}
  }
`;
