import React from 'react';

type Props = {
  className?: string;
  size?: number
};

const FotmobLogo: React.FC<Props> = ({ className, size = 26 }) => (
  <div className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 40 40">
      <defs>
        <clipPath id="4ius9irjpa">
          <path data-name="Rectangle 7737" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 8063" clipPath="url(#4ius9irjpa)">
        <path data-name="Path 4631" d="M19.942 0A19.933 19.933 0 0 0 .347 23.636l16.487-4.248a5.57 5.57 0 0 0-.172 1.632L1.173 26.689c.156.434.329.859.514 1.279l15.267-5.608a6.036 6.036 0 0 0 .642 1.224L3.034 30.512A19.941 19.941 0 1 0 19.942 0m-5.031 33.49H9.842V28.9l5.069-2.4zm6.53-9.323a3.438 3.438 0 0 1 0-6.876 3.438 3.438 0 0 1 0 6.876m8.6-12.7h-15.13v7.051l-5.069 1.317V7.909a1.543 1.543 0 0 1 1.515-1.515h17.172a1.542 1.542 0 0 1 1.516 1.515z" />
      </g>
    </svg>
  </div>

);

export default FotmobLogo;
