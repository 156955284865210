import React, { ReactElement } from 'react';

interface Props {
  backgroundColor?: string;
  color?: string;
}

const DownloadAppleStoreSvg = ({
  backgroundColor = '#fff',
  color = '#333',
}: Props): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g id="ic_app_store" transform="translate(-703 -5)">
      <circle
        id="Ellipse_312"
        cx="25"
        cy="25"
        r="25"
        fill={backgroundColor}
        transform="translate(703 5)"
      />
      <g id="XMLID_4_" transform="translate(717.883 15.393)">
        <g id="XMLID_5_">
          <path
            id="XMLID_7_"
            fill={color}
            d="M26.873 21.171a5.591 5.591 0 0 1 2.7-4.721 5.8 5.8 0 0 0-4.557-2.457c-1.919-.2-3.781 1.138-4.752 1.138-.994 0-2.5-1.126-4.123-1.092a6.064 6.064 0 0 0-5.106 3.106c-2.2 3.811-.56 9.408 1.553 12.491 1.062 1.513 2.3 3.2 3.918 3.128 1.588-.068 2.182-1 4.089-1s2.444 1 4.1.967c1.7-.023 2.776-1.513 3.792-3.037a12.748 12.748 0 0 0 1.736-3.515 5.419 5.419 0 0 1-3.35-5.008z"
            className="cls-2"
            transform="translate(-9.984 -7.997)"
          />
          <path
            id="XMLID_6_"
            fill={color}
            d="M22.5 12.69a5.451 5.451 0 0 0 1.268-3.97 5.711 5.711 0 0 0-3.666 1.888 5.229 5.229 0 0 0-1.3 3.822 4.7 4.7 0 0 0 3.698-1.74z"
            className="cls-2"
            transform="translate(-8.734 -8.72)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DownloadAppleStoreSvg;
